

<script>
import Navbar from "@/components/navbar-stable";
import Footer from "@/components/footer";
import WhatWeDo from "@/components/what-we-do-detail"

/**
 * index-9 component
 */
export default {
  name: "what-we-do",
  components: {
    Navbar,
    Footer,
    WhatWeDo
  },
};
</script>

<template>
  <div>
    <Navbar/>
    <WhatWeDo/>
    <Footer/>
  </div>
</template>