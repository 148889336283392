<template>
  <div>
    <section class="section bg-light margin-t-70">
      <div class="container" id="ozgecmis">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <h1 class="section-title text-center">NE YAPIYORUZ?</h1>
            <div class="section-title-border margin-t-20"></div>
          </div>
        </div>
        <div class="row vertical-content">
          <div class="col-lg-5">
            <div class="features-box">
              <h3>Profesyonel Özgeçmiş Düzenleme</h3>
              <p class="text-dark web-desc">
                Bilindiği gibi özgeçmiş, arzu ettiğimiz işe başlagıç için
                attığımız ilk adım.
              </p>
              <p class="text-dark web-desc">
                Özgeçmişinizi değerlendirecek ilk kişiyi özgeçmişle etkilemek
                başarı şansınızı arttıracağı da bir gerçek. Bu yüzden, olmasa da
                olur dediğimiz yahut kişisel verilerimizi eleyerek, çarpıcı
                olacak bilgileri öne çıkarmak için özgeçmişinizin bir İnsan
                Kaynakları profesyoneli tarafından düzenlenmesinin öneminin
                yadsınamaz olduğunu bilerek, özgeçmişinizi birlikte profesyonel
                hale getiriyoruz.
              </p>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="features-img features-right text-right">
              <img
                src="@/assets/images/whatwedo/cv_customizing.svg"
                alt="macbook image"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-lg bg-web-desc">
      <div class="bg-overlay"></div>
      <div class="container" id="linkedin-profil-danismanligi">
        <div class="row">
          <div class="col-lg-5">
            <div class="features-img features-right text-right">
              <img
                src="@/assets/images/whatwedo/linkedin_profile.svg" 
                alt="macbook image"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="col-lg-7">
            <div class="features-box">
              <h3>LinkedIn Profil Danışmanlığı</h3>
              <p class="text-dark web-desc">
                Dünya’nın en büyük profesyonel network ağının bir parçası
                mısınız?
              </p>
              <p class="text-dark web-desc">
                Günümüz dijital dünyasında var olabilmek ve içinde bulunmayı
                arzu ettiğiniz sektördeki şirket liderlerine direk
                ulaşabileceğiniz, yaptığınız işleri, yetkinliklerinizi,
                eğitiminizi sadece bir kişiyle değil, tüm dünyayla paylaştığınız
                bu portalda nasıl gözüktüğünüzü önemsiyorsanız gelin bu işin
                üstesinden beraber gelelim.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-pattern-effect">
        <img src="@/assets/images/bg-pattern-light.png" alt />
      </div>
    </section>

    <section class="section bg-light margin-t-70">
      <div class="container" id="kariyer-planlamasi">
        <div class="row vertical-content">
          <div class="col-lg-5">
            <div class="features-box">
              <h3>Kariyer Planı Oluşturma</h3>
              <p class="text-dark web-desc">
                2019 yılındaki Dünya Ekonomik Forumu'nda yer alan çalışan
                memnuniyeti anketine göre yaptığı işten mutlu olmayan yani
                mutsuz çalışan oranı Dünya'da %87, Türkiye'de ise %85 olarak
                belirlenmiş.
              </p>
              <p class="text-dark web-desc">
                Bunun nedeni işverenler mi? Yoksa bir çoğumuzun sevdiği ya da
                kendine uygun işi yapmıyor olması mı?
              </p>
              <p class="text-dark web-desc">
                Kariyer planı oluşturmak konusundaki genel yargı, üniversiteden
                yeni mezun olmuş birinin iş hayatına atılma serüveni olduğudur.
                Geneli böyle olsa da kariyer planı her yaşta ve her konumda
                yeniden çizilebilir yahut yapılandırılabilir. Kişinin
                yetkinlikleri, yatkın olduğu ve ilgi duyduğu işlerle
                karakterinin harmanlanması sonucu hayatını mutlu olduğu işi
                yaparak geçirmesi mümkün. Biz bu noktada kişinin kendisini ve
                işlerini profesyonel bir göz olarak sizinle beraber yorumlayıp
                daha detaylı irdelemenize yardımcı oluyoruz.
              </p>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="features-img features-right text-right">
              <img
                src="@/assets/images/whatwedo/career_plan.svg"
                alt="macbook image"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-lg bg-web-desc">
      <div class="bg-overlay"></div>
      <div class="container" id="mulakat-simulasyonu">
        <div class="row">
          <div class="col-lg-5">
            <div class="features-img features-right text-right">
              <img
                src="@/assets/images/whatwedo/online_interview2.svg"
                alt="macbook image"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="col-lg-7">
            <div class="features-box">
              <h3>Sektörel Mülakat Simülasyonu (Canlı)</h3>
              <p class="text-dark web-desc">
                Mülakatlara her zaman önyargıyla yaklaşıyoruz. Mülakatın, bizi
                değerlendiren İnsan Kaynakları Profesyoneline kendimizi tüm
                yönlerimizle açtığımız ve ona mevcut işi ne kadar da iyi
                yapabileceğimizi gösterme toplantısı olduğunu düşünüyoruz. Bu
                düşüncede doğruluk payı da olsa, iş arayan taraf için oldukça
                gergin bir başlangıcın nedeni.
              </p>
              <p class="text-dark web-desc">
                Öncelikle mülakatın bu soğuk tarafını yenmenin önemini
                konuşacağız, mülakatın asıl amacını tartışacağız, ardından
                sektörünüze ve mesleğinize uygun spesifik simülasyonlarla
                heyecanınızı atmanıza yardımcı olacağız.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-light margin-t-70">
      <div class="container" id="egitim">
        <div class="row vertical-content">
          <div class="col-lg-5">
            <div class="features-box">
              <h3>Kişisel ve Mesleki Eğitim</h3>
              <p class="text-dark web-desc">
                Bazen ne kadar önemli ve gerekli olduğunu bilsekte, içerik
                konusunda soru işaretleri yaşadığımız için eğitim online dahi
                olsa katılmaya hevesimiz olmuyor.
              </p>
              <p class="text-dark web-desc">
                Peki tam olarak size uygun olduğunuzu bildiğiniz, eğitim
                içeriğinin siz ve çevrenizdekilere uyarlanmış olması sizin için
                çekici olmaz mıydı?
              </p>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="features-img features-right text-right">
              <img
                src="@/assets/images/whatwedo/personal_education.svg"
                alt="macbook image"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>